$( document ).ajaxStop(function() {
  $("a.add_mutation_link").text("Gegevens bewerken");
  $(".myPersonalInformation").show();
  hidePrive();
  document.querySelector('#Block_SubScribeNewsletter_Block_SubScribeNewsletter_lblemailadresprive').style.display = 'block';
});
hidePrive();

function hidePrive () {
  if (document.querySelector('#Block_SubScribeNewsletter_pnlEmailServiceRegistrationForm')) {
    document.querySelector('#Block_SubScribeNewsletter_Block_SubScribeNewsletter_lblemailadresprive').style.display = 'none';
    document.querySelector('#Block_SubScribeNewsletter_Block_SubScribeNewsletter_lblemailadresprive').innerHTML = 'E-mailadres';
  }
}